import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseBtn from "../../assets/images/close.png";

const slideRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }`;

const slideUp = keyframes`

  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }`;

const slideLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }`;

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {

    opacity: 1; 
  }`;

const FooterInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 4rem 2rem;
  padding: 3rem 0;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
  animation-delay: 1s;

  > * {
    animation-delay: 2s;
  }

  > *:nth-child(1) {
    animation-delay: 0.2s;
  }

  > *:nth-child(2) {
    animation-delay: 0.4s;
  }

  > *:nth-child(3) {
    animation-delay: 0.6s;
  }

  > *:nth-child(4) {
    animation-delay: 0.8s;
  }

  > *:nth-child(5) {
    animation-delay: 1s;
  }

  > *:nth-child(6) {
    animation-delay: 1.2s;
  }
  > *:nth-child(7) {
    animation-delay: 1.4s;
  }
  > *:nth-child(8) {
    animation-delay: 1.6s;
  }
  > *:nth-child(9) {
    animation-delay: 1.8s;
  }
`;

const ContactInfo = styled.div`
  width: 40%;
  min-width: 300px;
  max-width: 100%;
  textwrap: wrap;
  overflow: hidden;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;

  h4 {
    font-family: "NunitoSansMedium", sans-serif;
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.05rem;
    margin: 5px 0;
  }
`;
const MapContainer = styled.div`
  width: 40%;

  min-width: 300px;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;

  iframe {
    width: 85%;
    max-width: 400px;
    height: 200px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 1.2px 3px 0.2px rgba(0, 0, 0, 0.3);
  }
`;
const FooterCopyright = styled.p`
  font-size: 1rem;
  padding: 1rem 0 0;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
  animation-delay: 1s;
`;

// Styled Components
const FooterContainer = styled.footer`
  // background-color: #f9f9f9;
  padding: 0 20px;
  text-align: center;

  @media (min-width: 1600px) {
    border-radius: 10px;
  }
`;

const FooterEdit = styled.div`
  font-size: 1rem;
  font-weight: 600;
  gap: 0.5rem;
  display: flex;
  cursor: pointer;
  padding: 0.5rem 2rem;
  width: fit-content;
  background-color: #ffe5e5;
  border-radius: 8px 8px 0 0;
  opacity: 0.85;
  box-shadow: 0 1.2px 1.5px 0 rgba(0, 0, 0, 0.3);
`;

const StyledFooterEdit = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
  animation-delay: 1s;

  @media (max-width: 849px) {
    flex-direction: column;
    gap: 1rem;

    /* Change the order of the elements when the flex direction is column */
    & > div:first-child {
      order: 1; /* Brukervilkår will be first */
    }

    & > div:last-child {
      order: 2; /* Personvernerklæring will be last */
    }

    & > ${FooterEdit} {
      order: 3; /* FooterEdit will be in the middle */
    }
  }
`;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 1200,
  borderRadius: 3,
  height: "auto",
  maxHeight: "80%",
  overflowY: "auto",
  overflowX: "hidden",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  WrapText: "wrap",
};

const StyledCloseBtn = styled.img`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  width: 20px;
  padding: 10px;
  cursor: pointer;

  @media (max-width: 399px) {
    right: 0.5rem;
  }
`;

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [isLoaded, setIsLoaded] = useState(false);
  const [isKontakt, setIsKontakt] = useState(false);
  const [showBrukervilkar, setShowBrukervilkar] = useState(false);
  const [showPersonvernerklaring, setShowPersonvernerklaring] = useState(false);
  const location = useLocation(); // Get the current location from React Router

  const [isMobile, setIsMobile] = useState(false);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = sessionStorage.getItem("userData");
    const parsedUserData = JSON.parse(fetchUserData);
    setUserData(JSON.parse(parsedUserData.acf.clinicdata));
    console.log(
      "User data fetched and stored:",
      JSON.parse(parsedUserData.acf.clinicdata)
    );
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    // Detect if user is on a mobile device
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize(); // check initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    if (isMobile) {
      // Trigger dial functionality
      window.location.href = "tel:12345678";
    }
  };

  const handleEmailClick = () => {
    // Trigger email functionality
    window.location.href = "mailto:post@test.no";
  };

  useEffect(() => {
    setIsLoaded(true); // Initial animation on mount
  }, []);

  useEffect(() => {
    // Trigger the animation reliably whenever the location changes
    setIsLoaded(false);

    const delayForAnimationReset = setTimeout(() => {
      setIsLoaded(true);
    }, 30); // Small delay ensures `isLoaded` is toggled properly

    return () => clearTimeout(delayForAnimationReset); // Cleanup timer
  }, [location]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newYear = new Date().getFullYear();
      if (newYear !== currentYear) {
        setCurrentYear(newYear);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [currentYear]);

  // check if navigation is on kontakt page
  useEffect(() => {
    if (location.pathname === "/kontakt") {
      setIsKontakt(true);
    } else {
      setIsKontakt(false);
    }
  }, [location]);

  return (
    <>
      {isLoaded && userData && (
        <FooterContainer
          style={
            {
              // background: "linear-gradient(180deg, #f2f9ff, #def0ff)",
              // background: "#F4F7FE",
              // boxShadow: "0 0px 2px 1px rgba(0, 0, 0, 0.05)",
            }
          }
        >
          {!isKontakt && (
            <FooterInfo isLoaded={isLoaded}>
              {userData.openingTimes.length > 0 && (
                <ContactInfo isLoaded={isLoaded}>
                  <h4>Åpningstider</h4>
                  {userData.openingTimes.map((time, index) => (
                    <>
                      <pre key={index} style={{ marginBottom: "15px" }}>
                        <span style={{ fontWeight: 600 }}>{time.title}</span>
                        <br />
                        {time.hours}
                      </pre>
                    </>
                  ))}
                </ContactInfo>
              )}

              {userData.doctorsOffice.googleMaps && (
                <MapContainer isLoaded={isLoaded}>
                  <iframe
                    title="Stranden Legesenter Location"
                    src={userData.doctorsOffice.googleMaps}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </MapContainer>
              )}

              {(userData.contactInfo.phone ||
                userData.contactInfo.email ||
                userData.contactInfo.fax ||
                userData.contactInfo.postboxAddress ||
                userData.contactInfo.postboxZipcity) && (
                <ContactInfo isLoaded={isLoaded}>
                  <h4>Kontakt</h4>
                  {userData.contactInfo.phone && (
                    <p onClick={handleClick} style={{ marginBottom: "5px" }}>
                      <span style={{ fontWeight: 600 }}>Tlf:</span>{" "}
                      {userData.contactInfo.phone}
                    </p>
                  )}
                  {userData.contactInfo.email && (
                    <p
                      onClick={handleEmailClick}
                      style={{ cursor: "pointer", marginBottom: "5px" }}
                    >
                      <span style={{ fontWeight: 600 }}>E-post:</span>{" "}
                      {userData.contactInfo.email}
                    </p>
                  )}

                  {userData.contactInfo.fax && (
                    <p style={{ marginBottom: "5px" }}>
                      <span style={{ fontWeight: 600 }}>Faks:</span>{" "}
                      {userData.contactInfo.fax}
                    </p>
                  )}

                  {userData.contactInfo.postboxAddress && (
                    <p style={{ marginBottom: "5px" }}>
                      <span style={{ fontWeight: 600 }}>Post:</span>{" "}
                      {userData.contactInfo.postboxAddress}
                    </p>
                  )}
                  {userData.contactInfo.postboxZipcity && (
                    <p>{userData.contactInfo.postboxZipcity}</p>
                  )}
                </ContactInfo>
              )}
              {(userData.doctorsOffice.address ||
                userData.doctorsOffice.zipcity) && (
                <ContactInfo isLoaded={isLoaded}>
                  <h4>Besøk</h4>
                  {userData.doctorsOffice.address && (
                    <p>{userData.doctorsOffice.address}</p>
                  )}
                  {userData.doctorsOffice.zipcity && (
                    <p>{userData.doctorsOffice.zipcity}</p>
                  )}
                </ContactInfo>
              )}
            </FooterInfo>
          )}

          <FooterCopyright
            isLoaded={isLoaded}
            style={{ paddingTop: isKontakt ? ".25rem" : "1rem" }}
          >
            {/* {userData.doctorsOffice.officeName} © {currentYear} */}
            <a href="https://ventral.no" target="_blank" rel="noreferrer">
              Ventral AS © {currentYear}
            </a>
          </FooterCopyright>
          <StyledFooterEdit isLoaded={isLoaded}>
            <div
              style={{
                width: 200,
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => setShowBrukervilkar(true)}
            >
              Brukervilkår
            </div>
            <FooterEdit>
              <SettingsOutlinedIcon
                style={{
                  fontSize: "1.4rem",
                }}
              />
              <a
                href="https://ventral.no/innlogging"
                target="_blank"
                rel="noreferrer"
              >
                Administrer nettsiden
              </a>
            </FooterEdit>
            <div
              style={{
                width: 200,
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => setShowPersonvernerklaring(true)}
            >
              Personvernerklæring
            </div>
          </StyledFooterEdit>
        </FooterContainer>
      )}

      <Modal
        open={showBrukervilkar}
        onClose={() => setShowBrukervilkar(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <>
            <StyledCloseBtn
              src={CloseBtn}
              alt="Close button"
              className="close-btn"
              onClick={() => setShowBrukervilkar(false)}
            />

            <h2
              style={{
                marginTop: 0,
                fontFamily: "NunitoSansRegular",
                letterSpacing: "2px",
                textAlign: "center",
                maxWidth: "90%",
              }}
            >
              {userData?.doctorsOffice?.officeName ? (
                <>Brukervilkår for {userData.doctorsOffice.officeName}</>
              ) : (
                <>Brukervilkår</>
              )}
            </h2>
            <pre
              style={{
                fontSize: "1.2rem",
                fontFamily: "NunitoSansRegular",
                letterSpacing: "1px",
                whiteSpace: "pre-wrap",
              }}
            >
              {userData?.doctorsOffice?.officeName ? (
                <>
                  <strong>Sist oppdatert: 25.03.2025</strong>
                  <br />
                  Disse brukervilkårene regulerer din bruk av denne nettsiden,
                  som er levert av Ventral AS og driftet av{" "}
                  {userData.doctorsOffice.officeName}. Ved å bruke nettsiden
                  godtar du disse vilkårene. Hvis du ikke godtar vilkårene, bør
                  du ikke bruke nettsiden.
                  <br />
                  <br />
                  <strong>1. Generelle betingelser</strong>
                  <br />
                  Denne nettsiden er levert av Ventral AS til{" "}
                  {userData.doctorsOffice.officeName} (videre omtalt som
                  "legekontoret"), som er ansvarlig for innholdet på nettsiden.
                  Nettsiden er hostet hos Domeneshop AS, som fungerer som en
                  tredjeparts webhotell-leverandør. Bruk av nettsiden skal skje
                  i samsvar med gjeldende lover og forskrifter. Ved å bruke
                  nettsiden aksepterer du disse vilkårene i sin helhet.
                </>
              ) : (
                <>
                  <strong>Sist oppdatert: 25.03.2025</strong>
                  <br />
                  Disse brukervilkårene regulerer din bruk av denne nettsiden,
                  som er levert av Ventral AS. Ved å bruke nettsiden godtar du
                  disse vilkårene. Hvis du ikke godtar vilkårene, bør du ikke
                  bruke nettsiden.
                  <br />
                  <br />
                  <strong>1. Generelle betingelser</strong>
                  <br />
                  Denne nettsiden er levert av Ventral AS. Nettsiden er hostet
                  hos Domeneshop AS, som fungerer som en tredjeparts
                  webhotell-leverandør. Bruk av nettsiden skal skje i samsvar
                  med gjeldende lover og forskrifter. Ved å bruke nettsiden
                  aksepterer du disse vilkårene i sin helhet.
                </>
              )}
              <br />
              <br />
              <strong>2. Bruk av innhold</strong>
              <br />
              Alt innhold på denne nettsiden er publisert av legekontoret, med
              mindre annet er oppgitt. Du har rett til å lese og bruke
              informasjonen på nettsiden til personlig bruk. Det er ikke tillatt
              å kopiere, distribuere eller endre innholdet uten skriftlig
              tillatelse fra legekontoret.
              <br />
              <br />
              <strong>3. Brukerens ansvar</strong>
              <br />
              Brukere av nettsiden skal ikke publisere eller laste opp ulovlig
              eller støtende innhold, forsøke å få uautorisert tilgang til
              nettsidens systemer eller servere, eller bruke nettsiden på en
              måte som kan skade nettsiden eller tredjeparts tjenester. Hvis
              nettsiden gir mulighet for brukergenerert innhold (f.eks.
              kommentarer), forbeholder legekontoret seg retten til å fjerne
              upassende innhold.
              <br />
              <br />
              <strong>4. Lenker til eksterne sider</strong>
              <br />
              Nettsiden kan inneholde lenker til eksterne nettsider som ikke
              drives av legekontoret. Vi er ikke ansvarlige for innholdet eller
              personvernspraksisen på eksterne nettsider. Bruk av eksterne
              lenker skjer på eget ansvar.
              <br />
              <br />
              <strong>5. Innlogging via Helsenorge.no</strong>
              <br />
              Nettsiden gir mulighet for innlogging til Helsenorge.no via en
              ekstern lenke. Vi lagrer ikke eller behandler informasjon knyttet
              til denne innloggingen. All behandling av personopplysninger skjer
              i henhold til Helsenorge.no sine egne personvernregler.
              <br />
              <br />
              <strong>6. Sikkerhet</strong>
              <br />
              Nettsiden er hostet hos Domeneshop AS, som har ansvar for
              serverdriften. Ventral AS og legekontoret tar forholdsregler for å
              beskytte nettsidens innhold, men kan aldri garantere full
              sikkerhet. Brukere oppfordres til å rapportere eventuelle
              sikkerhetsproblemer de oppdager.
              <br />
              <br />
              <strong>7. Ansvarsbegrensning</strong>
              <br />
              Nettsiden tilbys "som den er", uten garantier for tilgjengelighet
              eller feilfri drift. Verken Ventral AS eller legekontoret kan
              holdes ansvarlige for tap eller skader som følge av bruk av
              nettsiden. Vi forbeholder oss retten til å endre, fjerne eller
              begrense tilgang til nettsiden uten forvarsel.
              <br />
              <br />
              <strong>8. Bruk av Session Storage</strong>
              <br />
              Denne nettsiden benytter Session Storage i nettleseren din for å
              forbedre brukeropplevelsen. Session Storage lagrer midlertidige
              data som kun er tilgjengelige så lenge nettleseren er åpen. Når du
              lukker fanen eller nettleseren, slettes disse dataene automatisk
              fra Session Storage i nettleseren din. <br />
              <br />
              Hva lagres?
              <br /> <br /> • Tekst, bilder og informasjon som du angir i løpet
              av økten.
              <br /> • Tekst, bilder og informasjon fra tidligere publiserte
              økter, som hentes fra våre servere. <br />
              <br />
              Dataene lagres kun midlertidig i nettleseren din og blir ikke
              sendt til våre servere før du trykker på "Publiser"-knappen.
              Informasjonen fra tidligere publiserte økter forblir lagret på
              våre servere, selv om den slettes fra Session Storage i
              nettleseren din. Du kan fjerne Session Storage-dataene ved å enten
              lukke fanen / nettleseren eller manuelt slette dem via
              nettleserens utviklerverktøy.
              <br />
              <br />
              <strong>9. Sikkerhet og kryptering med SSL-sertifikat</strong>
              <br />
              Vi bruker SSL (Secure Sockets Layer) for å beskytte data som
              overføres mellom deg og vår nettside. SSL sikrer at all
              informasjon som sendes via nettsiden er kryptert og ikke kan
              avlyttes eller manipuleres av tredjeparter. Denne teknologien
              beskytter personopplysninger ved innsendelse av skjemaer, sikrer
              innloggingsprosesser og betalingsinformasjon dersom det er
              relevant, og forhindrer uautorisert tilgang til kommunikasjonen
              mellom deg og våre servere. Ved å bruke vår nettside samtykker du
              til bruk av SSL for å sikre dataoverføringen. Du kan bekrefte at
              forbindelsen er sikker ved å se etter et låsikon i nettleserens
              adressefelt.
              <br />
              <br />
              <strong>10. Priser og endringer</strong>
              <br />
              Våre priser følger <strong>Normaltariffen</strong> utgitt av Den
              norske legeforening, samt legekontorets egne satser. Prisene
              oppdateres hovedsakelig årlig, men kan justeres løpende ved
              endringer i Normaltariffen. Slike endringer kan tre i kraft uten
              forvarsel.
              <br />
              <br />
              <strong>11. Bruk av AI-genererte bilder</strong>
              <br />
              Vi kan ved anledning benytte AI-genererte bilder på våre
              nettsider. Disse bildene brukes for illustrasjonsformål og
              representerer ikke faktiske personer, steder eller produkter med
              mindre annet er spesifisert.
              <br />
              <br />
              <strong>12. Endringer i brukervilkårene</strong>
              <br />
              Vi kan oppdatere disse brukervilkårene ved behov. Den nyeste
              versjonen vil alltid være tilgjengelig på nettsiden.
              <br />
              <br />
              <strong>13. Kontaktinformasjon</strong>
              <br />
              Hvis du har spørsmål om disse brukervilkårene, vennligst kontakt:
              <br /> <br />
              Ventral AS
              <br />
              Org nr: 935 204 585
              <br />
              E-post: kontakt@ventral.no
              <br />
              Adresse: Hasleveien 26, 0571 Oslo
              <br />
              <br />
              Ved å bruke denne nettsiden godtar du disse brukervilkårene.
            </pre>
          </>
        </Box>
      </Modal>

      <Modal
        open={showPersonvernerklaring}
        onClose={() => setShowPersonvernerklaring(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <>
            <StyledCloseBtn
              src={CloseBtn}
              alt="Close button"
              className="close-btn"
              onClick={() => setShowPersonvernerklaring(false)}
            />

            <h2
              style={{
                marginTop: 0,
                fontFamily: "NunitoSansRegular",
                letterSpacing: "2px",
                textAlign: "center",
                maxWidth: "90%",
              }}
            >
              {userData?.doctorsOffice?.officeName ? (
                <>Personvernerklæring for {userData.doctorsOffice.officeName}</>
              ) : (
                <>Personvernerklæring</>
              )}
            </h2>
            <pre
              style={{
                fontSize: "1.2rem",
                fontFamily: "NunitoSansRegular",
                letterSpacing: "1px",
                whiteSpace: "pre-wrap",
              }}
            >
              <strong>Sist oppdatert: 25.03.2025</strong>
              <br />
              Denne nettsiden er en av flere nettsider levert av Ventral AS til
              legekontor i Norge. Ventral AS tar personvernet ditt på alvor.
              Denne personvernerklæringen beskriver hvordan personopplysninger
              samles inn, behandles og beskyttes i samsvar med gjeldende lover,
              inkludert EUs personvernforordning (GDPR) og norsk
              personvernlovgivning.
              {userData?.contactInfo?.phone ||
              userData?.contactInfo?.email ||
              userData?.contactInfo?.address ||
              userData?.doctorsOffice?.officeName ? (
                <>
                  <br />
                  <br />
                  <strong>1. Behandlingsansvarlig</strong>
                  <br />
                  Behandlingsansvarlig for personopplysningene som behandles på
                  denne nettsiden er:
                  <br />
                  <br />
                  Legekontor: {userData.doctorsOffice.officeName} <br />
                  Adresse: {userData.contactInfo.address} <br />
                  Telefon: {userData.contactInfo.phone} <br />
                  E-post: {userData.contactInfo.email} <br />
                  <br />
                  Denne nettsiden er levert av Ventral AS, men det er
                  legekontoret som er ansvarlig for innholdet på nettsiden og
                  behandlingen av personopplysninger som samles inn via
                  nettsiden. Nettsiden er hostet hos Domeneshop AS, en
                  tredjeparts webhotell-leverandør valgt av Ventral AS.
                  Webhotellet fungerer som en teknisk tilrettelegger for lagring
                  av nettsidens innhold, men har ingen innflytelse på hvilke
                  data som publiseres av legekontoret. <br />
                  Dette innebærer at visse personopplysninger kan lagres og
                  behandles på Domeneshop AS sine systemer. Ventral AS har
                  inngått en databehandleravtale med Domeneshop AS for å sikre
                  at personopplysninger behandles i samsvar med GDPR. Ventral AS
                  har imidlertid begrenset innflytelse på, og påtar seg ikke
                  ansvar for, hvordan Domeneshop AS drifter sine servere utover
                  det som er regulert i denne avtalen. For mer informasjon om
                  hvordan Domeneshop AS håndterer data, anbefaler vi å lese
                  deres{" "}
                  <a
                    style={{
                      color: "#007bff",
                      textDecoration: "underline",
                    }}
                    href="https://domene.shop/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    avtalevilkår og betingelser.
                  </a>
                </>
              ) : (
                <>
                  <br />
                  <br />
                  <strong>1. Behandlingsansvarlig</strong>
                  <br />
                  Denne nettsiden er levert av Ventral AS, men det er
                  legekontoret som er ansvarlig for innholdet på nettsiden og
                  behandlingen av personopplysninger som samles inn via
                  nettsiden. Nettsiden er hostet hos Domeneshop AS, en
                  tredjeparts webhotell-leverandør valgt av Ventral AS.
                  Webhotellet fungerer som en teknisk tilrettelegger for lagring
                  av nettsidens innhold, men har ingen innflytelse på hvilke
                  data som publiseres av legekontoret. <br />
                  Dette innebærer at visse personopplysninger kan lagres og
                  behandles på Domeneshop AS sine systemer. Ventral AS har
                  inngått en databehandleravtale med Domeneshop AS for å sikre
                  at personopplysninger behandles i samsvar med GDPR. Ventral AS
                  har imidlertid begrenset innflytelse på, og påtar seg ikke
                  ansvar for, hvordan Domeneshop AS drifter sine servere utover
                  det som er regulert i denne avtalen. For mer informasjon om
                  hvordan Domeneshop AS håndterer data, anbefaler vi å lese
                  deres{" "}
                  <a
                    style={{
                      color: "#007bff",
                      textDecoration: "underline",
                    }}
                    href="https://domene.shop/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    avtalevilkår og betingelser.
                  </a>
                </>
              )}
              <br />
              <br />
              <strong>2. Hvilke personopplysninger samles inn?</strong>
              <br />
              Nettsiden kan inneholde og behandle følgende personopplysninger,
              avhengig av hva kunden har valgt å publisere:
              <br />
              <br />• Identifikasjonsopplysninger: Navn og eventuelle jobbtitler
              som vises på nettsiden.
              <br />• Bilder: Bilder av ansatte og kontoret dersom dette er lagt
              inn av legekontoret.
              <br />• Kontaktinformasjon: Telefonnummer, e-postadresse og fysisk
              adresse/postboks for legekontoret.
              <br />• Informasjon om tjenester: Åpningstider, priser på
              tjenester og annen informasjon relatert til legekontoret.
              <br />• Innhold publisert av legekontoret: Artikler, nyheter og
              annen informasjon som legekontoret velger å legge ut.
              <br />• Lenker til eksterne nettsider: Legekontoret kan velge å
              legge inn lenker til relevante eksterne nettsider.
              <br />
              <br />
              Nettsiden lagrer ingen personopplysninger utover det som er
              oppgitt av legekontoret.
              <br />
              <br />
              <strong>3. Innlogging via Helsenorge.no</strong>
              <br />
              Nettsiden gir mulighet for innlogging til Helsenorge.no via en
              ekstern lenke. Vi gjør oppmerksom på at denne nettsiden ikke
              samler inn eller lagrer noen personopplysninger i forbindelse med
              denne innloggingen. All behandling av personopplysninger skjer på
              Helsenorge.no sin plattform, og vi anbefaler at du leser deres
              personvernerklæring for mer informasjon.
              <br />
              <br />
              <strong>4. Formål med behandling av personopplysninger</strong>
              <br />
              Personopplysningene som vises på nettsiden behandles for å:
              <br />
              <br />• Informere pasienter om legekontorets tjenester.
              <br />• Gi tilgang til kontaktinformasjon og åpningstider.
              <br />• Vise innhold publisert av legekontoret.
              <br />
              <br />
              Gi brukere mulighet til å navigere til eksterne ressurser.
              <br />
              <br />
              <strong>5. Rettslig grunnlag for behandling</strong>
              <br />
              Behandlingen av personopplysninger på denne nettsiden er basert
              på:
              <br /> <br />• Samtykke (dersom legekontoret velger å publisere
              bilder eller annen valgfri informasjon om ansatte og kontoret).
              <br /> <br />
              Berettiget interesse (for å gi pasienter relevant informasjon om
              legekontoret og tjenestene det tilbyr).
              <br />
              <br />
              <strong>6. Lagring og sletting av personopplysninger</strong>
              <br />
              Informasjon som publiseres på nettsiden lagres så lenge
              legekontoret velger å ha den tilgjengelig. Legekontoret kan når
              som helst redigere eller slette innhold fra nettsiden. Dataene
              lagres på serverne til <strong>Domeneshop AS</strong>, som Ventral
              AS benytter som webhotell. Domeneshop AS fungerer som en
              databehandler i henhold til GDPR, og det er inngått en
              databehandleravtale for å sikre sikker behandling av lagrede data.
              <br />
              <br />
              <strong>7. Dine rettigheter</strong>
              <br />
              Dersom personopplysninger om deg er publisert på denne nettsiden,
              har du rett til:
              <br /> <br />• Innsyn i hvilke opplysninger som er lagret om deg.
              <br />• Rettelse av uriktige opplysninger.
              <br />• Sletting av opplysninger som ikke lenger er relevante.
              <br />• Begrensning av behandlingen.
              <br />• Innsigelse mot behandlingen.
              <br /> <br />
              For å utøve dine rettigheter, kontakt legekontoret direkte.
              <br />
              <br />
              <strong>8. Deling av personopplysninger</strong>
              <br />
              Denne nettsiden deler ikke personopplysninger med tredjeparter med
              mindre: <br />
              <br />• Legekontoret har lagt inn lenker til eksterne nettsider
              hvor personopplysninger kan behandles. <br />• Det er juridisk
              pålagt å dele opplysninger med offentlige myndigheter. <br />
              <br />
              Brukere oppfordres til å lese personvernerklæringen til eksterne
              nettsider som de besøker via lenker fra denne nettsiden.
              <br />
              <br />
              <strong>9. Bruk av Session Storage</strong>
              <br />
              Denne nettsiden benytter Session Storage i nettleseren din for å
              forbedre brukeropplevelsen. Session Storage lagrer midlertidige
              data som kun er tilgjengelige så lenge nettleseren er åpen. Når du
              lukker fanen eller nettleseren, slettes disse dataene automatisk
              fra Session Storage i nettleseren din.
              <br />
              <br />
              Hva lagres?
              <br />
              <br />• Tekst, bilder og informasjon som du angir i løpet av
              økten.
              <br />• Tekst, bilder og informasjon fra tidligere publiserte
              økter, som hentes fra våre servere.
              <br />
              <br />
              Dataene lagres kun midlertidig i nettleseren din og blir ikke
              sendt til våre servere før du trykker på "Publiser"-knappen.
              Informasjonen fra tidligere publiserte økter forblir lagret på
              våre servere, selv om den slettes fra Session Storage i
              nettleseren din. Du kan fjerne Session Storage-dataene ved å enten
              lukke fanen / nettleseren eller manuelt slette dem via
              nettleserens utviklerverktøy.
              <br />
              <br />
              <strong>10. Bruk av SSL-sertifikat</strong>
              <br />
              Denne nettsiden benytter SSL (Secure Sockets Layer) for å sikre
              dataoverføring mellom deg og våre servere. SSL krypterer
              informasjon som sendes mellom nettleseren din og nettstedet, slik
              at den ikke kan leses eller endres av uvedkommende.
              <br /> <br />
              Hva sikres med SSL?
              <br /> <br />• Personlige data som sendes via kontaktskjemaer
              eller innloggingssider.
              <br />• Betalingsinformasjon (som oppgitt av deg).
              <br />• Kommunikasjon mellom nettleseren din og våre servere for å
              forhindre datalekkasjer.
              <br /> <br />
              SSL sørger for at all informasjon som overføres mellom deg og
              nettsiden vår er kryptert og beskyttet mot uautorisert tilgang. Du
              kan verifisere at tilkoblingen er sikker ved å se etter et låsikon
              i adressefeltet i nettleseren din.
              <br />
              <br />
              <strong>11. Sikkerhet</strong>
              <br />
              Ventral AS tar datasikkerhet på alvor og har implementert tekniske
              og organisatoriske tiltak for å beskytte informasjonen på
              nettsiden. Nettsiden er hostet på et webhotell som følger
              bransjestandarder for sikkerhet, inkludert kryptert kommunikasjon
              og regelmessige sikkerhetsoppdateringer. Legekontoret er ansvarlig
              for innholdet som legges ut og oppfordres til å sikre at sensitiv
              informasjon ikke publiseres.
              <br />
              <br />
              <strong>12. Endringer i personvernerklæringen</strong>
              <br />
              Legekontoret forbeholder seg retten til å oppdatere denne
              personvernerklæringen ved behov. Den nyeste versjonen vil alltid
              være tilgjengelig på nettsiden.
              <br />
              <br />
              <strong>13. Kontaktinformasjon</strong>
              <br />
              Hvis du har spørsmål eller klager knyttet til behandlingen av
              personopplysninger på denne nettsiden, vennligst kontakt:
              <br /> <br />
              {userData?.contactInfo?.phone ||
              userData?.contactInfo?.email ||
              userData?.doctorsOffice?.officeName ? (
                <>
                  Legekontor: {userData.doctorsOffice.officeName}
                  <br />
                  E-post: {userData.contactInfo.email}
                  <br />
                  Telefon: {userData.contactInfo.phone}
                </>
              ) : (
                <>Legekontoret</>
              )}
              <br /> <br />
              Du har også rett til å klage til Datatilsynet dersom du mener at
              dine rettigheter ikke overholdes:
              <br /> <br />
              Nettsted:{" "}
              <a
                style={{
                  color: "#007bff",
                  textDecoration: "underline",
                }}
                href="https://www.datatilsynet.no"
                target="_blank"
                rel="noreferrer"
              >
                https://www.datatilsynet.no
              </a>
              <br />
              E-post: postkasse@datatilsynet.no
              <br />
              Telefon: +47 22 39 69 00
              <br /> <br />
              Ved å bruke denne nettsiden godtar du denne personvernerklæringen.
            </pre>
          </>
        </Box>
      </Modal>
    </>
  );
}

export default Footer;
