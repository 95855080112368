import React, { useEffect, useState } from "react";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
import styled, { keyframes } from "styled-components";

// Keyframes for sliding animation

const slideRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`

  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledP = styled.p`
  font-size: 0.9rem;
  margin: 0;
  margin-bottom: -4px;
  color: #999;
`;

function Ansatte() {
  const [isLoaded, setIsLoaded] = useState(false);

  const [userData, setUserData] = useState(null);

  ScrollToTop();

  useEffect(() => {
    // const fetchUserData = localStorage.getItem("userData");
    const fetchUserData = sessionStorage.getItem("userData");
    const parsedUserData = JSON.parse(fetchUserData);
    setUserData(JSON.parse(parsedUserData.acf.clinicdata));
    console.log(
      "User data fetched and stored:",
      JSON.parse(parsedUserData.acf.clinicdata)
    );
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded && userData && (
        <>
          {userData.employees.length > 0 && (
            <EmployeeList>
              {userData.employees
                .sort(
                  (a, b) => (a.description ? 1 : 0) - (b.description ? 1 : 0)
                ) // Sorting the employees
                .map((employee, index) => (
                  <EmployeeCard isLoaded={isLoaded} key={index}>
                    <div style={{ display: "flex" }}>
                      <EmployeeImage
                        style={{
                          borderRadius: employee.description
                            ? "0 0 5px 0"
                            : "0",
                        }}
                        src={
                          employee.image
                            ? employee.image
                            : "https://ventral.no/legenettside_backend/wp-content/uploads/2025/03/placeholderAnsatt.png"
                        }
                        alt={
                          employee.name ? employee.name : "Placeholder image"
                        }
                      />

                      <EmployeeInfo>
                        {employee.name && (
                          <EmployeeName>{employee.name}</EmployeeName>
                        )}
                        {employee.jobTitle && (
                          <EmployeeProfession>
                            {employee.jobTitle}
                          </EmployeeProfession>
                        )}

                        {employee.specialist && (
                          <>
                            <StyledP
                              style={{
                                marginBottom: -5,
                                marginTop: 5,
                              }}
                            >
                              Spesialist i
                            </StyledP>
                            <EmployeeSpecialization>
                              {employee.specialist}
                            </EmployeeSpecialization>
                          </>
                        )}
                      </EmployeeInfo>
                    </div>
                    {employee.description && (
                      <EmployeeSpecialization
                        style={{ padding: "15px", color: "#666" }}
                      >
                        {employee.description}
                      </EmployeeSpecialization>
                    )}
                  </EmployeeCard>
                ))}
            </EmployeeList>
          )}
        </>
      )}
    </>
  );
}

const EmployeeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;

  margin: 2rem auto 3rem;
  justify-content: space-evenly;

  /* Targeting the children */
  > * {
    animation-delay: 0.2s;
  }

  > *:nth-child(1) {
    animation-delay: 0.2s;
  }

  > *:nth-child(2) {
    animation-delay: 0.4s;
  }

  > *:nth-child(3) {
    animation-delay: 0.6s;
  }

  > *:nth-child(4) {
    animation-delay: 0.8s;
  }

  > *:nth-child(5) {
    animation-delay: 1s;
  }

  > *:nth-child(6) {
    animation-delay: 1.2s;
  }
  > *:nth-child(7) {
    animation-delay: 1.4s;
  }
  > *:nth-child(8) {
    animation-delay: 1.6s;
  }
  > *:nth-child(9) {
    animation-delay: 1.8s;
  }

  @media (max-width: 599px) {
    width: 98%;
  }
`;

const EmployeeCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: fit-content;
  max-width: 400px;
  min-height: 150px;
  height: fit-content;
  // border: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  box-shadow: 0 1.2px 3px 0.2px rgba(0, 0, 0, 0.3);

  border-radius: 8px;
  overflow: hidden;
  margin: 1rem;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
`;

const EmployeeImage = styled.img`
  width: 105px;
  height: 150px;
  object-fit: cover;
`;

const EmployeeInfo = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const EmployeeName = styled.h3`
  font-size: 1.2rem;
  margin: 0;
  margin-top: -5px;
  color: #333;
`;

const EmployeeProfession = styled.pre`
  font-size: 1rem;
  margin: 0 5px 5px 0;
  color: #666;
  white-space: pre-wrap;
`;

const EmployeeSpecialization = styled.pre`
  font-size: 0.9rem;
  margin: 0;
  color: #999;
  white-space: pre-wrap;
`;

export default Ansatte;
